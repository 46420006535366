<template>
    <div class="container">
        <header class="jumbotron">
            <h3>Dodaj raport</h3>
        </header>
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
                <label for="quarter">Kwartał</label>
                <Dropdown id="quarter" v-model="content.quarter" :options="quarters" placeholder="Wybierz kwartał"/>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="year">Rok</label>
                <Dropdown id="year" v-model="content.year" :options="years" placeholder="Wybierz rok"/>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="selectType">Rodzaj</label>
                <Dropdown id="selectType" v-model="selected" :options="types"
                          placeholder="Rodzaj"/>
            </div>
        </div>
        <div class="p-field p-col-12 p-md-6 fa-pull-right">
            <Button type="button" class="p-button-secondary" label="Wróć" v-on:click="goBack"/>
            <Button type="button" label="Zapisz" v-on:click="click"/>
        </div>
    </div>
</template>

<script>
    import ReportService from "../../services/reports.service";

    export default {
        inject: ['global'],
        name: "AddReport",
        props: {
            initialCntent: {
                // eslint-disable-next-line vue/require-valid-default-prop
                type: Object, default: {
                    quarter: 4,
                    year: 2021,
                }
            }
        },
        data() {
            return {
                content: this.initialCntent,
                selected: 'Z danych deklaracji',
                quarters: [1, 2, 3, 4],
                years: [2021, 2022],
                types: ['Z danych deklaracji', 'Z danych dot. zużycia wody']
            };
        },
        methods: {
            click: function () {
                if (this.selected === 'Z danych dot. zużycia wody') {
                    ReportService.addReportWater(this.content).then(
                        () => {
                            this.$router.push({path: `${this.global.state.instancePatch}/raporty/woda/lista`});
                        },
                        (error) => {
                            this.content =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();
                        }
                    );
                } else {
                    ReportService.addReport(this.content).then(
                        () => {
                            this.$router.push({path: `${this.global.state.instancePatch}/raporty/deklaracje/lista`});
                        },
                        (error) => {
                            this.content =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();
                        }
                    );
                }
            },
            goBack: function () {
                if(this.selected === 'Z danych dot. zużycia wody'){
                    this.$router.push({path: `${this.global.state.instancePatch}/raporty/woda/lista`});
                } else {
                    this.$router.push({path: `${this.global.state.instancePatch}/raporty/deklaracje/lista`});
                }
            }
        },
    };
</script>
